@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip-bar {
    background: #353535;
    border: 1px solid #515050;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    height: auto;
    padding: 4px;
    color: #ffffff;
    pointer-events: none;
    position: absolute;
    text-align: center;
    width: auto;
    font-weight: bold;
}


.tooltip-bar span {
    color: #FFD422;
}

body {
    font-family: 'Poppins', sans-serif;
}

.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}